.cover {
    position: relative;
    padding-bottom: 30px;
                
    &:before {
        content: '';
        position: absolute;
        @include absolute($top: 60px, $left: 0);
        border-radius: 0 $border-radius--home $border-radius--home 0;
        background: $color-second;
        min-height: 350px;
        height: calc(100% - 100px);
        width: calc(100% - ((100% - ($large - $gl-gutter))/2) + 55px);
    }

    &__container {
        position: relative;
        display: flex;
        column-gap: 50px;
        padding-right: 50px;

        &:after {
            content: '';
            position: absolute;
            right: -160px;
            top: -120px;
            background: url(/wp-content/themes/gambais/assets/src/images/loup.svg) no-repeat center;
            @include size(310px,325px);
            z-index: -1;
        }
    }

    &__image-wrapper {
        position: relative;
        @include size(calc(50% - 25px),450px);
        background-color: $color-bg--image;
        border-radius: $border-radius--home;

        &:before, &:after {
            content: '';
            position: absolute;
        }
                
        &:before {
            @include absolute($bottom: -30px, $left: -30px);
            border-radius: $border-radius--home;
            @include size(100%,275px);
            background: $color-third;
        }

        // &:after {
        //     @include absolute($top: 0, $left: 0);
        //     @include size(100%);
        //     border-radius: $border-radius--home;
        //     background: linear-gradient(90deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.27) 30.51%, rgba(0, 0, 0, 0.06) 70.31%, rgba(0, 0, 0, 0.00) 100%);
        //     z-index: 2;
        // }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            border-radius: $border-radius--home;
            z-index: 1;
            position: relative;
        }
    }

    &__informations {
        padding: 30px 0 80px;
        width: calc(50% - 25px);
        margin-top: 60px;
    }

    &__title {
        position: relative;
        color: $color-white;
        margin: 10px 0 15px;
    }

    &__intro {
        position: relative;
        color: $color-white;
        font-size: toRem(18);
        line-height: toRem(25);
    }
}


//======================================================================================================
// Responsive
//======================================================================================================
// 1200
@media screen and (max-width: $large) { 
    .cover { 
        &:before {
            min-height: 280px;
            width: calc(100% - ((100% - ($medium - $gl-gutter-md))/2) + 50px);
        }

        &__container {
            padding-right: 0;

            &:after {
                background-size: contain;
                right: -130px;
                top: -100px;
                @include size(250px,260px);
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &:before {
            min-height: 200px;
            width: 100%;
            height: calc(100% - 60px);
            border-radius: 0;
        }

        &__container {
            padding-right: 0;
            flex-direction: column-reverse;

            &:after {
                right: -155px;
                top: -85px;
            }
        }

        &__image-wrapper {
            @include size(100%,380px);

            &:before {
                width: calc(100% - 70px);
                height: calc(100% - 70px);
                @include absolute($right: -30px, $bottom: -45px, $left: auto);
            }
        }

        &__informations {
            width: 100%;
            padding: 10px 0 40px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__image-wrapper {
            height: 215px;
        }

        &__container {
            &:after {
                display: none;
            }
        }
    }

}
