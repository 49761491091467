.home {
    h2, h3 {
        &:before {
            display: none;
        }
    }
    
    &__header {
        @include flex($alignItems: flex-end, $justifyContent: space-between);
    }

    &__title {
        margin: 0;
        color: $color-main;
        font-size: toRem(50);
        font-weight: $font-weight-black;
        line-height: toRem(55); 
        text-transform: uppercase;
        display: inline-flex;
        flex-direction: column; 
        position: relative;
        z-index: 1;

        &:after {
            content: '';
            position: absolute;
            right: -40px;
            bottom: -10px;
            background: url(/wp-content/themes/gambais/assets/src/images/sprite-images/pictos/svg_title.svg) no-repeat center;
            @include size(55px);
            z-index: -1;
        }
    }

    &__subtitle {
        color: $color-main;
        font-size: toRem(30);
        font-weight: $font-weight-light;
        line-height: toRem(35); 
        text-transform: uppercase;
    }

    &__header {
        @include flex($alignItems: flex-end, $justifyContent: space-between);
    }

    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .alu {
        position: relative;
        padding-bottom: 60px;

        &__container {
            position: relative;

            &:before, &:after {
                content: '';
                position: absolute;
            }
                
            &:before {
                @include absolute($top: 205px, $right: 0);
                border-radius: $border-radius--home;
                @include size(716px,380px);
                background: $color-third;
            }

            &:after {
                right: -160px;
                top: -120px;
                background: url(/wp-content/themes/gambais/assets/src/images/loup.svg) no-repeat center;
                @include size(310px,325px);
                z-index: -1;
            }
        }
                
        &:before {
            content: '';
            position: absolute;
            @include absolute($top: 90px, $left: 0);
            border-radius: 0 $border-radius--home $border-radius--home 0;
            background: $color-second;
            height: 320px;
            width: calc(100% - ((100% - ($large - $gl-gutter))/2) + 55px);
        }
    
        > .container {
            position: relative;
        }
    
        .container {
            &--content {
                position: relative;
                height: 100%;
            }
    
            &--pagination {
                @include absolute(0, 0, 0, 0);
                height: 100%;
                pointer-events: none
            }
        }

        &__swiper {
            overflow: initial;
        }

        &__slide {
            border-radius: $border-radius--home;
            overflow: hidden;
        }
    
        .slideshow {
            @include size(calc(100% - 50px), 450px);
            border-radius: $border-radius--home;
            margin: 0;
    
            img, video {
                position: absolute;
                object-fit: cover;
                object-position: top;
                @include size(100%);
            }
    
            &__container {
                display: inherit;
                position: relative;
                @include size(100%);
                
                &:before {
                    content: '';
                    position: absolute;
                    @include absolute($top: 0, $left: 0);
                    @include size(100%);
                    background: linear-gradient(90deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.27) 30.51%, rgba(0, 0, 0, 0.06) 70.31%, rgba(0, 0, 0, 0.00) 100%);
                    z-index: 2;
                }
            }
    
            &__content {
                @include flex($direction: column);
                box-sizing: border-box;
                @include absolute($left: 30px, $bottom: 60px);
                @include size(300px, auto);
                z-index: 3;
            }
    
            &__title {
                margin-bottom: 5px;
                font-size: toRem(22);
                line-height: toRem(30);
                font-weight: $font-weight-medium;
                text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.50);
                color: $color-white;
            }

            &__description {
                text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.50);
                line-height: toRem(25);
                color: $color-white;
            }
    
            &--video {
                overflow: hidden;
                img {
                    display: none;
                }
            }
            // Pagination (bullet)
            &__pagination {
                @include absolute($bottom: 30px, $left: 30px);
                @include flex($alignItems: center, $justifyContent: center);
                column-gap: 10px;
                z-index: 2;
                width: auto;
                pointer-events: initial;
        
                .swiper-pagination-bullet {
                    @include size(10px);
                    background-color: $color-white;
                    border-radius: 5px;
                    opacity: 1;
                    transition: {
                        duration: $duration;
                        timing-function: $timing;
                    }
        
                    &:hover, &:focus, &-active {
                        background-color: $color-fourth;
                    }
                }
            }
        
        }
    }

    //======================================================================================================
    // Accès rapides
    //======================================================================================================
    .access {
        position: relative;
        z-index: 1;

        &__list {
            @include flex($alignItems: center, $justifyContent: space-between);
            padding-right: 55px;
        }

        &__item {
            border-radius: 30px;
            background-color: $color-light;
            @include size(140px);
            @include flex($alignItems: center, $justifyContent: center, $direction: column);
            row-gap: 10px;

            &:hover, 
            &:focus-within {
                .access {
                    &__svg-container {
                        &:before {
                            @include size(35px);
                        }
                    }
                }
            }
        }

        &__label {
            text-align: center;
            font-size: $font-size--text-small;
            line-height: toRem(18);
            font-weight: $font-weight-semibold;
        }

        &__svg-container {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                background-color: $color-third;
                opacity: .6;
                right: -8px;
                top: -5px;
                @include size(0);
                border-radius: $border-radius;
                transition: .35s;
            }

            svg {
                position: relative;
            }

            &--1 {
                &:before {
                    right: -8px;
                    top: 25px;
                }
            }

            &--2 {
                &:before {
                    left: -3px;
                    top: 5px;
                }
            }

            &--3 {
                &:before {
                    right: -8px;
                    top: -5px; 
                }
            }

            &--4 {
                &:before {
                    left: 0px;
                    top: 15px;
                }
            }

            &--5 {
                &:before {
                    right: -10px;
                    top: 5px;
                }
            }

            &--6 {
                &:before {
                    left: -10px;
                    top: 25px;
                }
            }
        }
    }

    //======================================================================================================
    // Actualités
    //======================================================================================================
    .posts {
        padding: 80px 0;

        &__title {
            &:after {
                bottom: -20px;
                right: -35px;
                transform: rotate(180deg);
            }
        }

        &__list {
            @include flex($alignItems: center, $wrap: wrap);
            gap: 30px;
            margin-top: 40px;
        }

        &__item {
            display: flex;
            width: calc(50% - 15px); 
        }

        &__link {
            @include flex($alignItems: center);
            column-gap: 30px;
            height: 205px;

            &:hover, &:focus-within {
                .posts {
                    &__link {
                        &__container-img {
                            &:before {
                                bottom: -15px;
                                left: -15px;
                            }
                        }

                        &__taxo {
                            &:before {
                                background-color: $color-second;
                            }
                        }
                    }
                }
            }

            &__container-img {
                position: relative;
                border-radius: $border-radius--home;
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-light no-repeat center;
                @include size(270px,100%);
                flex: none;
    
                img {
                    @include size(100%);
                    object-fit: cover;
                    border-radius: $border-radius--home;
                }

                &:before {
                    content: '';
                    position: absolute;
                    @include size(240px,175px);
                    background-color: $color-second;
                    border-radius: $border-radius--home;
                    bottom: -10px;
                    left: -10px;
                    z-index: -1;
                    transition: $duration $timing;
                }
            }

            &__content {
                @include flex($direction: column);
                row-gap: 10px;
                width: 100%;
            }

            &__taxo {
                @include flex($alignItems: center);
                column-gap: 10px;
                font-weight: $font-weight-light;
                text-transform: uppercase;
                line-height: toRem(25);

                &:before {
                    content: '';
                    background-color: $color-fourth;
                    @include size(15px);
                    border-radius: 4px;
                    transition: $duration $timing;
                }
            }

            &__title {
                font-size: toRem(20);
                line-height: toRem(25);
                font-weight: $font-weight-medium;
                margin: 0;
            }

            &__intro {
                font-size: $font-size--text-small;
                line-height: toRem(20);
            }
        }

        &__template {
            margin-bottom: 5px;

            &__btn {
                padding: 0;
                margin: 0;
                width: 270px;
            }
        }
    }
    
    //======================================================================================================
    // Evènements
    //======================================================================================================
    .events {
        background-color: $color-light;
        padding: 80px 0;

        &--special {
            .events__item {
                &:nth-child(2n) {
                    margin-top: 0;
                }
            }
        }

        &__list {
            display: flex;
            column-gap: 30px;
            margin-top: 40px;
        }

        &__item {
            display: block;
            flex: 1 1 0;

            &:nth-child(even){
                margin-top: 30px;
            }
        }

        &__link {
            position: relative;
            width: calc(100% / 3 - 30px);

            &:hover, &:focus-within {
                .events {
                    &__link {
                        &__date {
                            background-color: $color-main;
                            color: $color-white;
                            right: 0;
                        }
                    }
                }
            }

            &__content {
                position: relative;
                @include size(100%,205px);
            }

            &__container-img {
                border-radius: $border-radius--home;
                background: url(/wp-content/themes/noyau/assets/images/icon-events.png) $color-white no-repeat center;
                overflow: hidden;
                @include size(100%,100%);
    
                img {
                    @include size(100%);
                    object-fit: cover;
                    transform: scale(1);
                    transition: .35s ease-in-out;
                }
            }

            &__date {
                position: absolute;
                background-color: $color-third;
                border-radius: 15px;
                padding: 10px 15px;
                min-width: 90px;
                min-height: 50px;
                @include flex($direction: column, $alignItems: center, $justifyContent: center);
                font-size: toRem(18);;
                line-height: toRem(25);
                font-weight: $font-weight-black;
                text-transform: uppercase;
                bottom: -10px;
                right: -10px;
                box-sizing: content-box;
                transition: $duration $timing;
            }

            &__title {
                margin: 20px 0 0;
                font-size: toRem(20);
                line-height: toRem(25);
                font-weight: $font-weight-medium;
            }
        }

        &__template {
            display: flex;
            column-gap: 30px;
            margin-bottom: 5px;

            &__btn {
                padding: 0;
                margin: 0;
                width: 270px;
            }
        }
    }

    //======================================================================================================
    // Follow - nous suivre
    //======================================================================================================
    .follow {
        position: relative;
        background-color: $color-light;

        &:after {
            content: '';
            position: absolute;
            background-color: $color-main;
            @include size(100%,50%);
            bottom: 0;
        }

        &__title {
            flex:1;
            flex-direction: row;

            br {
                display: none;
            }

            &:after {
                position: relative;
                margin-left: -6px;
                right: 0;
                bottom: 0;
            }
        }

        &__container {
            background-color: $color-white;
            border-radius: $border-radius--home;
            @include flex($alignItems: center);
            padding: 0 50px;
            @include size(100%,160px);
            position: relative;
            z-index: 1;
        }

        &__content {
            @include flex($alignItems: center);
            column-gap: 50px;
        }

        &__wrapper-btn {
            @include flex($alignItems: center);
            column-gap: 20px;
        }

        &__btn {
            padding: 0;
            margin: 0;
            height: 50px;
            column-gap: 10px;

            svg {
                @include size(20px);

                path {
                    transition: $duration;
                }
            }

            &--nl {
                width: 170px;
            }

            &--app {
                width: 150px;
            }

            &:hover, &:focus-within {
                svg {
                    fill: $color-white;

                    path {
                        fill: $color-white;
                    }
                }
            }
        }

        &__wrapper-link {
            @include flex($alignItems: center);
            column-gap: 10px;
        }

        &__link {
            padding: 0;
            margin: 0;
            @include size(50px);

            svg {
                @include size(50px);

                path {
                    transition: $duration;
                }
            }

            &:hover, &:focus-within {
                svg {
                    fill: $color-text;

                    path {
                        fill: $color-text;
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home {
        &__title {
            font-size: toRem(40);
            line-height: toRem(45);

            &:after {
                @include size(50px);
                background-size: contain;
            }
        }

        &__subtitle {
            font-size: toRem(25);
            line-height: toRem(30);
        }

        .alu {
            &__container {
                &:before {
                    @include size(585px,310px);
                }

                &:after {
                    background-size: contain;
                    right: -130px;
                    top: -100px;
                    @include size(250px,260px);
                }
            }
                    
            &:before {
                height: 240px;
                width: calc(100% - ((100% - ($medium - $gl-gutter-md))/2) + 50px);
            }
        
            .slideshow {
                @include size(calc(100% - 40px), 370px);        
            }
        }

        .access {
            &__list {
                padding-right: 0;
            }
        }

        .posts {         
            &__title {
                &:after {
                    right: -30px;
                    bottom: -18px;
                }
            }

            &__link {
                column-gap: 20px;
    
                &__container-img {
                    @include size(205px,100%);
    
                    &:before {
                        @include size(175px);
                    }
                }
            }
        }

        .events {
            &__list {
                column-gap: 20px;
            }
    
            &__link {
                width: calc(100% / 3 - 20px);
    
                &__date {
                    bottom: -10px;
                    right: -10px;
                }
            }
    
            &__template {
                column-gap: 20px;
                margin-bottom: 5px;
            }
        }

        .follow {
            &__title {
                &:after {
                    bottom: -15px;
                    margin-left: 0;
                    right: 15px;
                }
            }
            &__container {
                padding: 0 35px;
                @include size(100%,140px);
            }

            &__content {
                column-gap: 15px;
            }
    
            &__wrapper-btn {
                column-gap: 15px;
            }

            &__btn {
                &--nl {
                    width: 150px;
                }
    
                &--app {
                    width: 130px;
                }
            }
        }
    }    
}


// 960
@media screen and (max-width: $medium) {
    .home {
        .alu {
            &__container {
                &:before {
                    @include size(585px,220px);
                    right: -30px;
                }

                &:after {
                    right: -155px;
                    top: -85px;
                }
            }
                    
            &:before {
                @include size(100%,165px);
                border-radius: 0;
            }
        
            .slideshow {
                @include size(100%, 290px);    
                
                &__content {
                    @include absolute($left: 20px, $bottom: 45px);
                }

                &__pagination {
                    @include absolute($bottom: 20px, $left: 20px);
                }

                &--video {
                    video {
                        display: none;
                    }
    
                    img {
                        display: block;
                    }
                }
            }
        
        }

        .access {
            &__list {
                margin: auto;
                max-width: 520px;
                flex-wrap: wrap;
                row-gap: 20px;
            }
        }

        .posts {
            &__list {
                align-items: flex-start;
            }

            &__link {
                flex-direction: column;
                height: auto;
                row-gap: 20px;
                width: 100%;

                &__container-img {
                    @include size(100%,205px);

                    &:before {
                        height: calc(100% - 30px);
                        width: calc(100% - 30px);
                    }
                }
            }
        }

        .events {
            position: relative;

            &--special {
                padding: 80px 0 150px;

                .events__template {
                    bottom: 60px;
                    flex-direction: row;
                    column-gap: 10px;
                }
            }

            &__list {
                flex-wrap: wrap;
                gap: 30px;
            }

            &__item {
                width: calc(100% / 2 - 15px);
                flex: auto;

                &:nth-child(even){
                    margin-top: 0;
                }

                &:nth-child(3) {
                    margin-right: calc(100% / 2 + 15px);
                }
            }

            &__link {
                width: 100%;
            }

            &__template {
                margin-bottom: 0;
                flex-direction: column;
                @include flex($justifyContent: flex-end, $direction: column, $alignItems: flex-end);
                row-gap: 20px;
                position: absolute;
                bottom: 130px;
                left: 0;
                right: 0;
                max-width: 90%;
                margin: 0 auto;
                padding: 0 10px;
                width: 100%;
            }
        }

        .follow {
            &__container {
                padding: 0 4%;
            }

            &__content,
            &__wrapper-btn {
                column-gap: 10px;
            }

            &__title {
                br {
                    display: block;
                }
            }

            &__btn {
                span {
                    display: none;
                }

                &--nl,
                &--app {
                    width: 50px;
                }
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .home {
        .alu {
            > .container {
                max-width: 100%;
                padding: 0;
            }

            &__container {
                &:before {
                    @include size(450px,220px);
                    @include absolute($left: 28%, $right: auto, $top: auto,$bottom: -120px);
                }
                &:after {
                    display: none;
                }
            }
                    
            &:before {
                @include size(100%,50px);
                @include absolute($left: 0, $bottom: 15px, $top: auto);
            }
        
            .slideshow { 
                aspect-ratio: 55/35;
                height: auto;
                max-height: 380px;
                min-height: 215px;  
                border-radius: 0;
                
                &__content {
                    margin: 0 auto;
                    padding: 0 10px;
                    max-width: 90%;
                    @include absolute($left: 0, $bottom: 35px, $right:0);
                    width: 100%;
                }

                &__pagination {
                    margin: 0 auto;
                    padding: 0 10px;
                    max-width: 90%;
                    @include absolute($bottom: 15px, $left: 0, $right:0);
                    width: 100%;
                    justify-content: flex-start;
                }
            }
        
        }

        .access {
            &__list {
                column-gap: 20px;
                justify-content: center;
            }
        }

        .posts {
            position: relative;
            padding: 80px 0 155px;

            &__item {
                width: 100%;
            }

            &__template {
                @include flex($justifyContent: flex-end);
                position: absolute;
                left: 0;
                right: 0;
                bottom: 60px;
                max-width: 90%;
                margin: 0 auto;
                padding: 0 10px;
                width: 100%;
            }
        }

        .events {
            padding: 60px 0 245px;

            &--special {
                padding: 60px 0 245px;

                .events__template {
                    flex-direction: column;
                    align-items: center;
                    bottom: 80px;
                }
            }

            &__list {
                row-gap: 20px;
            }

            &__item {
                width: 100%;

                &:nth-child(3){
                    width: 100%;
                    margin-right: 0;
                }
            }

            &__template {
                flex-direction: row;
                flex-wrap: wrap;
                column-gap: 15px;
                bottom: 80px;
                justify-content: center;
            }
        }

        .follow {
            &__container {
                padding: 30px;
                flex-wrap: wrap;
                min-height: 230px;
                height: auto;
                row-gap: 30px;
            }

            &__content {
                flex-wrap: wrap;
                row-gap: 15px;
            }

            &__title {
                width: 100%;
                flex: auto;
            }
        }
    }
}
