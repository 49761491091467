.bloc-content--404 {
    h2 {
        margin: 0;
        border: 0;
        @include font-size(200);
        text-align: center;

        &:before {
            display: none;
        }
    }

    p {
        font-size: $font-size--5;
        text-align: center;
    }
}

.search--404 {
    width: 970px;

    .searchResult {
        border: 1px solid $color-light;
        background-color: $color-light;

        li {
            background-color: $color-light;
        }
    }

    .search-container {
        position: relative;
        @include flex($alignItems: center);
    
        .searchInput {
            border-color: $search-input-border-color--404 !important;
            
            &:focus {
                border-color: $search-input-border-color--404  !important;
            }
        }
    }
}

.grid-center-noGutter.container__buttons {
    margin: 30px 0;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .bloc-content--404 {
        h2 {
            @include font-size(160);
        }
    }

}
