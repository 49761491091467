.footer {
    background-color: $color-main;
    padding: 80px 0 65px;

    > .container {
        row-gap: 60px;
        display: grid;
    }

    &__title {
        font-size: $font-size--4;
        line-height: toRem(25);
        color: $color-white;
        font-weight: $font-weight-medium;
        margin: 0 0 10px;
    }

    &__text {
        color: $color-white;
    }

    &__infos {
        @include flex($alignItems: flex-end);
    }

    &__logo {
        display: flex;
        margin-right: 50px;
        padding-bottom: 5px;
    }

    &__address {
        width: 230px;
        margin-right: 75px;
    }

    &__hours {
        width: 430px;
    }

    &__phone {
        color: $color-white;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: text-decoration-color .25s ease-in-out;

        &:hover, &:focus-within {
            color: $color-white;
            text-decoration-color: $color-white;
        }
    }

    &__buttons {
        @include flex($direction: column, $alignItems: flex-end, $justifyContent: flex-end);
        grid-row: 1/3;
        grid-column: 2/2;
        row-gap: 60px;
    }

    &__contact {
        @include size(220px,50px);
        margin: 0;
        padding: 0;
    }

    &__localisation {
        margin: 0;
        padding: 0;
        display: inline-flex;
        align-items: center;
        font-size: $font-size--text-small;
        color: $color-white;
        font-weight: $font-weight-medium;
        column-gap: 5px;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: text-decoration-color .25s ease-in-out;

        &:hover, &:focus-within {
            color: $color-white;
            text-decoration-color: $color-white;
        }
    }

    &__menu {
        @include flex($alignItems: center);
        column-gap: 20px;

        &__item {
            @include flex($alignItems: center);
            column-gap: 20px;

            &:after {
                content: '';
                display: inline-block;
                @include size(1px,18px);
                background-color: $color-white;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

        }

        &__link {
            color: $color-white;
            font-size: $font-size--text-small;
            line-height: toRem(25);
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: text-decoration-color .25s ease-in-out;

            &:hover, &:focus-within {
                color: $color-white;
                text-decoration-color: $color-white;
            }
        }
    }

    &__partners {
        @include flex($alignItems: center, $justifyContent: center);
        column-gap: 35px;
        width: 100%;
        grid-row: 3;
        grid-column: 1/3;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer {
        padding: 75px 0 80px;
    
        > .container {
            row-gap: initial;
            display: flex;
            flex-wrap: wrap;
        }
    
        &__infos {
            @include flex($alignItems: flex-end);
        }
    
        &__address {
            margin-right: 105px;
        }
    
        &__buttons {
            flex-direction: row;
            margin-top: 30px;
            margin-left: 135px;
            column-gap: 110px;
            align-items: center;
        }
    
        &__menu {
            margin: 60px 0;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        &__infos {
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            text-align: center;
            row-gap: 30px;
            margin: auto;
        }

        &__logo, &__address {
            margin-right: 0;
        }

        &__address, &__hours {
            width: auto;
        }

        &__buttons {
            margin-left: 0;
            flex-direction: column;
            width: 100%;
            row-gap: 30px;
        }

        &__menu {
            flex-wrap: wrap;
            row-gap: 10px;
            justify-content: center;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        &__partners {
            column-gap: 20px;
            row-gap: 10px;
            flex-wrap: wrap;
        }
    }
}