.header {
    z-index: 5;
    width: 100%;
    position: absolute;
    top: 0;

    &:after {
        content: '';
        position: absolute;
        top: -80px;
        @include size(100%,80px);
        background-color: $color-bg--transparent;
        z-index: -1;
        transition: .35s ease-in-out;
    }

    &__container {
        position: relative;
        height: 180px;
        padding-bottom: 30px;
        @include flex($alignItems: flex-end);
    }
}

//======================================================================================================
// Identity
//======================================================================================================
.identity {
    @include flex($alignItems: center, $justifyContent: center);
    @include size(fit-content);
    position: absolute;
    /* @include absolute($bottom: 0, $left: 0); */
    z-index: 1;

    &__title {
        margin: 0;
        color: $color-main;
        font-size: toRem(60);
        font-weight: $font-weight-black;
        line-height: toRem(65); 
        text-transform: uppercase;
        display: inline-flex;
        flex-direction: column; 
        position: relative;
        z-index: 1;

        &:after {
            content: '';
            position: absolute;
            right: -50px;
            bottom: 5px;
            background: url(/wp-content/themes/gambais/assets/src/images/sprite-images/pictos/logo_svg_title.svg) no-repeat center;
            @include size(70px);
            z-index: -1;
        }
    }

    &__subtitle {
        color: $color-main;
        font-size: toRem(35);
        line-height: toRem(40); 
        letter-spacing: 1.75px;
        text-transform: uppercase;
        font-weight: $font-weight;
    }
}

//======================================================================================================
// Tools
//======================================================================================================
.tools {
    @include absolute($top: 0, $right: 50px);
    @include flex($alignItems: center);
    column-gap: 20px;
    z-index: 2;

    &__item {
        @include flex($justifyContent: center, $alignItems: center);
        @include size(auto,40px);
        margin: 0;
        padding: 0 20px 0 10px;
        background-color: $color-bg--transparent;
        border: 0;
        border-radius: 0px 0px $border-radius $border-radius;
        cursor: pointer;
        color: $color-white;
        text-transform: uppercase;
        font-size: $font-size--text-small;
        line-height: toRem(20);
        font-weight: $font-weight-bold;

        svg {
            fill: $color-white;
            @include size(40px);
            transition: fill $duration $timing;
        }

        &--accessibility {
            background-color: $color-main;

            &:hover, &:focus-within {
                background-color: $color-light;
                color: $color-main;

                svg {
                    fill: $color-main;
                }
            }
        }

        &--search {
            background-color: $color-second;

            &:hover, &:focus-within {
                background-color: $color-light;
                color: $color-main;
                
                svg {
                    fill: $color-main;
                }
            }

            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }
    
    }

}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    &__container {
        display: flex;
        justify-content: flex-end;

        #google_translate_element {
            display: none;
        }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin-top: 45px;
            margin-right: 60px;
            padding: 15px 20px 20px;
            background-color: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: padding-left $duration $timing;

                        &:hover, &:focus {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}

//======================================================================================================
// Header fixed
//======================================================================================================
@keyframes headerFixed {
    from {
        top: -200px;
    }
    50% {
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}
@keyframes headerFixedAdmin {
    from {
        top: -200px;
    }
    to {
        top: 32px;
    }
}

.header--fixed {
    position: fixed;

    &:after {
        top: 0;
        background-color: $color-main;
    }

    .header {
        &__container {
            height: 80px;
            padding-bottom: 0;
            animation: 1s headerFixed;
            transition: .5s ease-in-out;
        }
    }

    .identity {
        height: 100%;
        &__title {
            color: $color-white;
            font-size: toRem(45);
            line-height: toRem(50);

            &:after {
                right: -35px;
                background: url(/wp-content/themes/gambais/assets/src/images/sprite-images/pictos/logo_svg_title_fixed.svg) no-repeat center;
                @include size(45px);
            }
        }

        &__subtitle {
            display: none
        }
    }

    .tools {
        height: 100%;
        right: 0;

        &__item {
            @include size(40px);
            border-radius: 5px;
            padding: 0;

            span {
                display: none;
            }

            &--accessibility {
                background-color: $color-white;

                svg {
                    fill: $color-main;
                }

                &:hover, &:focus-within {
                    background-color: $color-second;

                    svg {
                        fill: $color-white;
                    }
                }
            }

            &--search {
                &:hover, &:focus-within {
                    background-color: $color-white;

                    svg {
                        fill: $color-main;
                    }
                }
            }
        }
    }

    .nav-main {
        height: 100%;
        display: flex;
        padding-right: 175px;
        padding-left: 300px;

        &__container {
            width: 100%;
        }

        .menu__link {
            color: $color-white!important;
    
            &:after {
                background-color: $color-white;
            }
        }

        .submenu {
            top: 50px;
        }
    }
}
.admin-bar {
    .header--fixed {
        .header__container {
            top: 32px;
            animation: 1s headerFixedAdmin forwards ;
            opacity: 1;
        }
    }
} 


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        &__container {
            height: 165px;
        }
    }

    .identity {    
        &__title {
            font-size: toRem(50);
            line-height: toRem(55); 
    
            &:after {
                background-size: contain;
                bottom: 7px;
                right: -42px;
                @include size(60px);
            }
        }
    
        &__subtitle {
            font-size: toRem(25);
            line-height: toRem(35); 
            letter-spacing: 1.25px;
        }
    }

    .tools {
        right: 0;
    }

    .header--fixed {
        .nav-main {
            padding-left: 290px;
            padding-right: 130px;

            .menu {
                &__link {
                    font-size: $font-size--text-small;
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .header {
        position: relative;
        
        &:after {
            display: none;
        }
    }

    .identity {
        &__title {
            &:after {
                bottom: 3px;
                right: -50px;
            }
        }
    }

    .tools {
        column-gap: 10px;

        &__item {
            @include size(40px);
            padding: 0;

            span {
                display: none;
            }
        }
    }

    .tools-view {
        top: calc(100% + 5px);
    }

}


// 640
@media screen and (max-width: $small) {
    .header {
        position: relative;

        &:before {
            content: '';
            @include position(absolute, $top: 0, $left: 0);
            @include size(100%,50px);
            background-color: $color-main;
        }

        &__container {
            height: 200px;
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    .identity {
        position: relative;
    }

    .tools {
        &__item {
            &--accessibility {
                background-color: $color-white;

                svg {
                    fill: $color-main;
                }
            }
        }
    }

    .tools-view {
        &__container {
            .profils {
                margin-top: 80px;
                margin-right: 35px;
            }
        }
    }

}
