.cover {
    &__heading {
        display:flex;
        align-items: center;
        margin-bottom: 40px;
    }

    &__date {
        display: flex;
        align-items: center;
        padding: 5px 15px 5px 0;
        box-shadow: 1px 0 0 rgb(40 40 40 / 10%);

        p {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        svg {
            position: relative;
            width: 26px;
            height: 26px;
            margin: 0 10px;
            transform: rotate(-90deg);
        }
    }

    &__date-day {
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--1;
        line-height: $line-height--heading;
    }

    &__date-month {
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--3;
        text-transform: uppercase;
    }

    &__zone-title {
        padding: 0 0 0 20px;
    }

    &__category {
        font-family: $font-family--heading;
        font-size: $font-size--text-medium;
        text-transform: uppercase;
    }

    &__recurrence {
        p {
            margin: 20px 0 0;
            color: $color-gray;
        }
    }
}

.events-v2 {
    .contextual-publications {
        .card {
            &--square {
                .card__image-wrapper {
                    height: 150px;
                }
                .card__container {
                    height: 310px;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .events-v2 {
        .container {
            &--events {
                .col_sm-12 {
                    padding: 0;

                    .listing {
                        &__line {
                            padding: 15px 0;
                        }

                        &--accessibility {
                            padding: 25px 0;

                            .listing__line--accessibility {
                                padding: 0;
                                margin: 0;
                            }
                        }

                        .listing-contact {
                            a {
                                font-size: 0.938rem; // 15px
                            }
                        }
                    }
                }
            }
        }

        .contextual-publications {
            .card {
                &--square {
                    .card__image-wrapper {
                        height: 125px;
                    }
                    .card__container {
                        height: 290px;
                    }
                }
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .events-v2 {
        .contextual-publications {
            .card {
                &--square {
                    .card__image-wrapper {
                        height: 180px;
                    }
                    .card__container {
                        height: 330px;
                    }
                }
            }
        }
        
        .grid {
            margin: 0;
        }
        
        .actions-event, .content-infos {
            .container {
                width: 100%;
                margin: 0;
                padding: 0;
            }
        }
    }

    .cover {
        &__heading {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;
        }

        &__date {
            box-shadow: none;
        }

        &__zone-title {
            padding: 0;
        }
    }
    
}
