.breadcrumb {
    ul {
        display: block;
        min-height: 60px;
        padding: 5px 0;
    }

    li {
        position: relative;
        display: inline;
        color: $color-white;
        line-height: toRem(25);

        a {
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: text-decoration-color .25s ease-in-out;

            &:hover, &:focus {
                text-decoration-color: $color-white;
                color: $color-white;

            }
        }

        svg {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            fill: $color-white;
            vertical-align: middle;
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .breadcrumb {
        ul {
            min-height: 40px;
        }

        li svg {
            margin: 0;
        } 
    }

}
