//======================================================================================================
// Bloc Image
//======================================================================================================
.bloc-image {
    display: flex;
    margin: 30px 0;

    &__container {
        position: relative;
        display: inline-flex;
        justify-content: center;
        max-width: 100%;

        a {
            position: relative;

            &[target="_blank"]::after {
                content: "\f504";
                font: normal 1rem/1 dashicons;
                position: absolute;
                bottom: 3px;
                float: right;
                margin-left: 2px;
                color: $color-main;
            }
        }

        img {
            max-width: 100%;
        }
    }

  &__caption {
        position: absolute;
        bottom: 20px;
        max-width: 100%;
        padding: 10px 40px;
        background-color: $color-bg-caption;
        color: $color-caption;
        pointer-events: none;
        border-radius: 4px
    }

    &.original {
        img {
            @include size(100%);
            object-fit: cover;
            object-position: top;
        }
    }

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: flex-end;
    }
}
